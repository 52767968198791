.main {
  min-height: calc(100vh - var(--header-height));
  width: 780px;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .main {
    width: 1080px;
  }
}

.main h1 {
  text-align: center;
}

section {
  max-width: 90%;
  margin: 0 auto;
}
