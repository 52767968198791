.superblock-logo-wrap {
  margin: 0 auto;
  width: 30%;
  min-width: 234px;
}

.project-wrap {
  background-color: white;
  padding: 20px 25px;
  margin-bottom: 5px;
}

.project-wrap p {
  padding-left: 25px;
}

.prerequisites li {
  margin-bottom: 5px;
}

.instructions li {
  margin-bottom: 15px;
}
